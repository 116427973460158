[
  {
    "id": "doomsayer",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [],
    "setup": false,
    "name": "Doomsayer",
    "team": "fabled",
    "ability": "If 4 or more players live, each living player may publicly choose (once per game) that a player of their own alignment dies."
  },
  {
    "id": "angel",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [
      "Protected",
      "Protected",
      "Something Bad"
    ],
    "setup": false,
    "name": "Angel",
    "team": "fabled",
    "ability": "Something bad might happen to whoever is most responsible for the death of a new player."
  },
  {
    "id": "buddhist",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [],
    "setup": false,
    "name": "Buddhist",
    "team": "fabled",
    "ability": "For the first 2 minutes of each day, veteran players may not talk."
  },
  {
    "id": "hellslibrarian",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [
      "Something Bad"
    ],
    "setup": false,
    "name": "Hell’s Librarian",
    "team": "fabled",
    "ability": "Something bad might happen to whoever talks when the Storyteller has asked for silence."
  },
  {
    "id": "revolutionary",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [
      "Register Falsely?"
    ],
    "setup": false,
    "name": "Revolutionary",
    "team": "fabled",
    "ability": "2 neighboring players are known to be the same alignment. Once per game, 1 of them registers falsely."
  },
  {
    "id": "fiddler",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [],
    "setup": false,
    "name": "Fiddler",
    "team": "fabled",
    "ability": "Once per game, the Demon secretly chooses an opposing player: all players choose which of these 2 players win."
  },
  {
    "id": "toymaker",
    "firstNightReminder": "",
    "otherNight": 1.1,
    "otherNightReminder": "If it is a night when a Demon attack could end the game, and the Demon is marked “Final night: No Attack,” then the Demon does not act tonight. (Do not wake them.)",
    "reminders": [
      "Final Night: No Attack"
    ],
    "setup": false,
    "name": "Toymaker",
    "team": "fabled",
    "ability": "The Demon may choose not to attack & must do this at least once per game. Evil players get normal starting info."
  },
  {
    "id": "fibbin",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [
      "No Ability"
    ],
    "setup": false,
    "name": "Fibbin",
    "team": "fabled",
    "ability": "Once per game, 1 good player might get false information."
  },
  {
    "id": "duchess",
    "firstNightReminder": "",
    "otherNight": 1.2,
    "otherNightReminder": "Wake each player marked “Visitor” or “False Info” one at a time. Show them the Duchess token, then fingers (1, 2, 3) equaling the number of evil players marked “Visitor” or, if you are waking the player marked “False Info,” show them any number of fingers except the number of evil players marked “Visitor.”",
    "reminders": [
      "Visitor",
      "Visitor",
      "False Info"
    ],
    "setup": false,
    "name": "Duchess",
    "team": "fabled",
    "ability": "Each day, 3 players may choose to visit you. At night*, each visitor learns how many visitors are evil, but 1 gets false info."
  },
  {
    "id": "sentinel",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [],
    "setup": true,
    "name": "Sentinel",
    "team": "fabled",
    "ability": "There might be 1 extra or 1 fewer Outsider in play."
  },
  {
    "id": "spiritofivory",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [
      "No More Evil"
    ],
    "setup": false,
    "name": "Spirit of Ivory",
    "team": "fabled",
    "ability": "There can't be more than 1 extra evil player."
  },
  {
    "id": "djinn",
    "firstNight": 1.1,
    "firstNightReminder": "Inform the group of all Djinn special rules for this game.",
    "otherNightReminder": "",
    "reminders": [],
    "setup": false,
    "name": "Djinn",
    "team": "fabled",
    "ability": "Use the Djinn's special rule. All players know what it is."
  },
  {
    "id": "stormcatcher",
    "firstNight": 1.3,
    "firstNightReminder": "Announce the good 'Safe' character. Mark the player with that character as 'Safe'. Wake each evil player and show them the marked player.",
    "otherNightReminder": "",
    "reminders": [
      "Safe"
    ],
    "setup": false,
    "name": "Storm Catcher",
    "team": "fabled",
    "ability": "Name a good character. If in play, they can only die by execution, but evil players learn which player it is."
  },
  {
    "id": "bootlegger",
    "firstNight": 1.2,
    "firstNightReminder": "Inform the group of all homebrew characters or rules for this game.",
    "otherNightReminder": "",
    "reminders": [],
    "setup": false,
    "name": "Bootlegger",
    "team": "fabled",
    "ability": "This script has homebrew characters or rules."
  },
  {
    "id": "ferryman",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [],
    "setup": false,
    "name": "Ferryman",
    "team": "fabled",
    "ability": "On the final day, all dead players regain their vote token."
  },
  {
    "id": "gardener",
    "firstNightReminder": "",
    "otherNightReminder": "",
    "reminders": [],
    "setup": false,
    "name": "Gardener",
    "team": "fabled",
    "ability": "The Storyteller assigns 1 or more players' characters."
  }
]
